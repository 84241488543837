import React from "react";
import Section from "../../components/Section";
import Coverflow from "coverflow-react-minor-tweaks";

function Television(props) {
    const televisionShows = [
        "img/tv/BigWolfOnCampus.jpg",
        "img/tv/HopeAndGloria.jpg",
        "img/tv/LosLuchadores.jpg",
        "img/tv/StarTrekVoyager.jpg",
        "img/tv/TheNewAddamsFamily.jpg",
        "img/tv/TheFreshPrinceOfBelAir.jpg",
        "img/tv//MisguidedAngels.jpg",
        "img/tv/PacificBlue.jpg",
        "img/tv/SilkStalkings.jpg"
    ];
    return (
        <Section {...props}>
            <div className="container" style={{ height: "100vh" }}>
                <h1 className="text-white-50 typewriter">TELEVISION</h1>
                <div style={{marginTop:100}}><Coverflow imagesArr={televisionShows} height={200}/></div>
                {/* <Coverflow
                    width="960"
                    height="480"
                    displayQuantityOfSide={2}
                    navigation
                    infiniteScroll
                    enableScroll={true}
                    clickable={true}
                    active={5}
                    otherFigureScale={1}
                    
                >
                    <img src="img/tv/BigWolfOnCampus.jpg" alt="Big Wolf on Campus" />
                    <img src="img/tv/HopeAndGloria.jpg" alt="Hope & Gloria" />
                    <img src="img/tv/LosLuchadores.jpg" alt="Los Luchadores" />
                    <img src="img/tv//MisguidedAngels.jpg" alt="Misguided Angels" />
                    <img src="img/tv/PacificBlue.jpg" alt="PacificBlue" />
                    <img src="img/tv/SilkStalkings.jpg" alt="Silk Stalkings" />
                    <img src="img/tv/StarTrekVoyager.jpg" alt="Star Trek: Voyager" />
                    <img src="img/tv/TheFreshPrinceOfBelAir.jpg" alt="The Fresh Price of Bel-Air" />
                    <img src="img/tv/TheNewAddamsFamily.jpg" alt="The New Addams Family" />
                </Coverflow> */}

            </div>
        </Section>
    );
}

export default Television;