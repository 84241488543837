import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import Header from "./pages/Header";
import Latest from "./pages/Latest";
import Nav from "./components/Nav";
import Books from "./pages/Books";
import Television from "./pages/Television";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";

class App extends Component {
  state = {
    testValue: ""
  }

  componentDidMount() {
    
  }

  render() {
    const ref = React.createRef();
    return (
      <>
        {/* <!-- Navigation --> */}
        <Nav />
        <Header id="home" className="masthead"  />
        <Latest id="latest" className="about-section text-center" />
        <Books id="books" className="projects-section bg-light" />
        <Television id="television" className="projects-section bg-dark" />
        <Contact id="contact" className="signup-section" />
        <Footer />
      </>
    );
  }
}

export default App;
