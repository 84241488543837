import React from "react";
import Section from "../../components/Section";

function Books(props) {
    return <Section {...props}>
        <div className="container" style={{ height: "100vh" }}>
            <h1 className="typewriter">BOOKS</h1>
            <div className="book_shelf">
                <div className="book coming-soon">
                    <img src="img/books/neardeath.png" alt="" srcSet="" />
                </div>

                <div className="book coming-soon">
                    <div className="title" style={{ fontSize: "2.5vh" }}>Untitled<br />Ghost<br />Story<br />Anthology</div>
                    <div className="author">Peter R Talley<br />Rich Hosek<br />and others</div>
                </div>
                <div className="book coming-soon">
                    <div className="title" style={{ fontSize: "4.5vh" }}>After<br />Life</div>
                    <div className="author">Rich Hosek<br />Arnold Rudnick<br />Loyd Auerbach</div>
                </div>
                <div className="book coming-soon">
                    <div className="title" style={{ fontSize: "3.5vh" }}>The<br />Dead<br />Kids<br />Club</div>
                    <div className="author">Rich Hosek</div>
                </div>
                <div className="book coming-soon">
                    <div className="title" style={{ fontSize: "3.5vh" }}>The <br />Tenth<br />Ride</div>
                    <div className="author">Rich Hosek</div>
                </div>
            </div>
        </div>
    </Section>
}

export default Books;