import React from "react";
import Section from "../../components/Section";

function Contact(props) {
    return (
        <Section {...props}>
            <div className="container" style={{ height: "100vh" }}>
                <div className="row">
                    <div className="col-md-10 col-lg-8 mx-auto text-center">

                        <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
                        <h2 className=" typewriter text-white mb-5">Subscribe to receive updates!</h2>

                        <form className="form-inline d-flex" action="subscribe" method="POST">
                            <input type="email" className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0" name="email" id="email" placeholder="Enter email address..." />
                            <button type="submit" className="btn btn-primary mx-auto">Subscribe</button>
                        </form>

                    </div>
                </div>

                <div className="row mt-4">

                    <div className="col-md-4 offset-md-2 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <i className="fab fa-twitter-square fa-3x"></i>
                                <h4 className="text-uppercase m-0">@RichHosek</h4>
                                <hr className="my-4" />
                                <div className="small text-black-50"><a href="https://twitter.com/RichHosek" target="_blank">Follow me on twitter</a></div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <i className="fab fa-facebook-square fa-3x"></i>
                                <h4 className="text-uppercase m-0">@Rich Hosek</h4>
                                <hr className="my-4" />
                                <div className="small text-black-50">
                                    <a href="https://www.facebook.com/RichHosek" target="_blank">Visit me on Facebook</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default Contact;