import React from "react";
import Section from "../../components/Section";
import "./index.css";

const Top = React.forwardRef((props, ref) => {
    return (
        <Section {...props}>
            <div className="container d-flex h-100 align-items-center">
                <div className="this-is-rich"><img className="signature" src="img/signature.RichHosek.png" alt="Rich Hosek" /><img className="floating-head" src="img/floatinghead.png" alt="Floating Head" /></div>
                <div className="mx-auto text-center">
                    <h1 className="typewriter mx-auto my-0 text-uppercase">Rich Hosek</h1>
                    <h2 className="typewriter text-white-50 mx-auto mt-2 mb-5">Writer</h2>
                    <a href="#latest" className="btn btn-primary js-scroll-trigger">Latest</a>
                </div>
            </div>
        </Section>
    );
});

export default Top;