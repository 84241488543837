import React from "react";
import Section from "../Section";

function Footer(props) {
    return (
        <footer className="bg-black small text-center text-white-50">
            <div className="container">
                Copyright &copy; Rich Hosek 2020<br />
        cover photo: Ater a thunderstorm at Philmont Scout Ranch, New Mexico; social photo: Winter in Westchester
      </div>
        </footer>
    );
}

export default Footer;