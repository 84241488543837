import React from "react";
import "./index.css";
import Section from "../../components/Section";

const Latest = React.forwardRef((props, ref) => {
    return (
        <Section {...props}>
            <div className="container" _style={{ height: "100vh" }}>
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <h2 className="typewriter text-white mb-4">Raney and Daye</h2>
                        <p className="text-white-50">The first Raney and Daye novel, <strong>Near Death,</strong> is set to be published later this year.<br />If you would like to receive email updates, request an Advance Review Copy, or have general queries for myself, Arnold Rudnick or Loyd Auerbach, <a href="raneyanddaye.com/signup">please sign up here</a>.</p>
                        <h2 className="typewriter text-white mb-4">The Austin Intelligence</h2>
                        <p className="text-white-50">My short story, <strong>The Austin Intelligence</strong> has been accepted for publication in an anthology of ghost stories being edited by <a href="https://petertalley.com/" target="_blank">Peter R. Talley</a> </p>
                    </div>
                </div>
                <div className="book_shelf">
                    <div className="book">
                        <img src="img/books/neardeath.png" alt="" srcSet="" />
                    </div>

                    <div className="book">
                        <div className="title" style={{ fontSize: "2.5vh" }}>Untitled<br />Ghost<br />Story<br />Anthology</div>
                        <div className="author">Peter R Talley<br />Rich Hosek<br />and others</div>
                    </div>
                </div>
                <p className="text-white-50 mt-4"></p>
            </div>
        </Section>
    );
});

export default Latest;